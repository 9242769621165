
import { useHistory } from "react-router-dom";
import logoBrand from "../assets/img/ahorro_inteligente_brand.png";

const Header = () => {
  let history = useHistory();
  const SignOut = (e: any) => {
    e.preventDefault();
    localStorage.removeItem("token");
    history.push("/");
  };
  return(
    <header className="header-simulador">
      <img className="brand" src={logoBrand} alt="logo" />
      <h1>Simulador de crédito</h1>
      <button className="btn btn-secondary" onClick={SignOut} >Cerrar Sesión</button>
    </header>
  )
}

export default Header
