import Home from "../pages/Home";
import { Routing } from "../shared/interface/router";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
/**
 * Constante que carga la configuración de cada path
 * de la plataforma para su renderizado dinamico en root
 * de la aplicación.
 */
const routeritems: Routing[] = [
  {
    path: "/",
    exact: true,
    component: Home,
    name: "Home",
    private: true,
  },
  {
    path: "/login",
    exact: true,
    component: Login,
    name: "Login",
    private: false,
  },
  {
    path: "*",
    exact: true,
    component: NotFound,
    name: "NotFound",
    private: false,
  },
];

export default routeritems;
