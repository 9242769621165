import { useLocation } from "react-router-dom";

const NotFound = () => {
  let location = useLocation();

  return (
    <div>
      <h1>404</h1>
      <p>Page not found <code>{location.pathname}</code></p>
    </div>
  );
}

export default NotFound;
