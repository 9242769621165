import md5 from "md5";
import { Redirect, useHistory } from "react-router-dom";
import { useState } from "react";
import isLoggedIn from "../core/auth/ProvideAuth";
import logoBrand from "../assets/img/ahorro_inteligente_brand.png";

const Login = () => {
  let history = useHistory();

  const [password, setPassword] = useState<any>();
  const [errorLogin, setErrorLogin] = useState<string>("");

  const handChange = (e: any) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const handSubmit = (e: any) => {
    e.preventDefault();
    const token = md5(password);
    if (token === process.env.REACT_APP_PASSWORD) {
      // password correct 4h0rr01nt3l1g3nt3%
      localStorage.setItem("token", token);
      history.push("/");
    } else {
      setErrorLogin("Contraseña incorrecta");
    }
  };

  return (
    <main className="sigin-main">
      {isLoggedIn() ? <Redirect to="/" /> : null}
      <section className="sigin-container">
        <img className="brand" src={logoBrand} alt="logo" />
        <h1>Simulador de créditos</h1>
        <form>
          <div className="form-group">
            <input
              id="password"
              className="form-control"
              name="password"
              placeholder="Contraseña"
              type="password"
              onChange={handChange}
            />
          </div>
          {errorLogin ? (
            <small className="error-login">{errorLogin}</small>
          ) : null}
          <button
            className="btn btn-primary"
            onClick={handSubmit}
            type="submit"
          >
            Iniciar sesión
          </button>
        </form>
      </section>
    </main>
  );
};

export default Login;
