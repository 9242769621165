import { PropsWithRef } from "react";
import { Redirect } from "react-router-dom";
import isLoggedIn from '../core/auth/ProvideAuth';
import Header from "./Header";

const Layout = ({ children }: PropsWithRef<any> ) => {
  return(
    <>
      <Header />
      <main className="main-simulador">
        {isLoggedIn() ? children : <Redirect to="/login" />}
      </main>
    </>
  )
}

export default Layout;
