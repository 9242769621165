import { Simulador } from "../shared/interface/simulador";
import logoBrand from "../assets/img/ahorro_inteligente_brand.png";
import NumberFormat from "react-number-format";

const containerPdf: any = {
  width: "100%",
  height: "100%",
  maxWidth: "612px",
  maxHeight: "780px",
  backgroundColor: "white",
  color: "black",
  padding: "15px",
  fontSize: "10px",
};

const brand: any = {
  height: "50px",
};

const Header: any = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent: "center",
  marginBottom: "10px",
};

const containerHeaderText: any = {
  width: "100%",
  textAlign: "right",
};

const headerText: any = {
  margin: "0",
};

const contentText: any = {
  margin: "0",
};

const contentTextHonorarios: any = {
  margin: "0",
  backgroundColor: "#f4ee00",
  padding: "3px",
  borderRadius: "3px",
};

const sectionDataText: any = {
  margin: "15px 0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  alignContent: "center",
};

const contentData: any = {
  width: "50%",
};

const sectionText: any = {
  margin: "15px 0",
};

const titleContentText: any = {
  width: "100%",
  color: "#02b3a3",
  borderBottom: "1px solid #f4ee00",
  fontSize: "12px",
};

const optionCredit: any = {
  margin: "15px 0",
  padding: "5px 10px",
  borderRadius: "5px",
  border: "1px solid #f4ee00",
  overFlow: "hidden",
};

const optionCreditTitle: any = {
  width: "100%",
  backgroundColor: "#02b3a3",
  color: "white",
  margin: "-15px -5px 5px 0",
  borderRadius: "5px",
  padding: "0px 10px",
};

const Footer: any = {
  marginTop: "5px",
  textAlign: "center",
  display: "block",
  fontSize: "10px",
};

const PrintPdf = ({
  nombre,
  apellido,
  banco,
  numero_credito,
  cuotas_pendientes,
  valor_cuota,
  saldo_credito,
  intereses,
  asesor,
  opcion_1,
  opcion_2,
  opcion_3,
}: Simulador) => {
  const saldo_credito_aprox = cuotas_pendientes * valor_cuota;

  const valorNuevaCuota = (opcion: number): number => {
    return Number(
      ((saldo_credito / opcion) + (saldo_credito * (intereses / 100))).toFixed(0)
    );
  };

  const interesesCliente =
    ((saldo_credito * intereses) / 100) * cuotas_pendientes;

  const valorNuevosIntereses = (opcion: number): number => {
    return Number((saldo_credito * (intereses / 100) * opcion).toFixed(0));
  };

  const DatePrint = (): string => {
    const date = new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (month < 10) {
      return `${day}/0${month}/${year}`;
    } else {
      return `${day}/${month}/${year}`;
    }
  };
  return (
    <article className="container-pdf">
      <div id="cotizacion" style={containerPdf}>
        <div style={Header}>
          <img style={brand} src={logoBrand} alt="logo" />
          <div style={containerHeaderText}>
            <p style={headerText}>
              <strong>Ahorro Inteligente S.A.S </strong>
            </p>
            <p style={headerText}>
              <strong>NIT: 901.562.132-3 </strong>
            </p>
            <p style={headerText}>
              <strong>Fecha de la propuesta: </strong> {DatePrint()}
            </p>
            <p style={headerText}>
              <strong>Agente financiero: </strong> {asesor}
            </p>
          </div>
        </div>
        <p style={titleContentText}>
          <strong>Datos básicos y financieros del cliente </strong>
        </p>
        <div style={sectionDataText}>
          <div style={contentData}>
            <p style={contentText}>
              <strong>Nombre del cliente: </strong> {nombre} {apellido}
            </p>
            <p style={contentText}>
              <strong>Nombre de la entidad financiera: </strong> {banco}
            </p>
            <p style={contentText}>
              <strong>Crédito Nº </strong> {numero_credito}
            </p>
          </div>
          <div style={contentData}>
            <p style={contentText}>
              <strong> Saldo crédito: </strong> <NumberFormat value={saldo_credito} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>
            </p>
            <p style={contentText}>
              <strong>Cuotas pendientes: </strong> {cuotas_pendientes}
            </p>
            <p style={contentText}>
              <strong> Valor cuota: </strong> <NumberFormat value={valor_cuota}thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>
            </p>
            <p style={contentText}>
              <strong> Intereses: </strong> {intereses}
            </p>
            <p style={contentText}>
              <strong>Valor Total aproximado a pagar: </strong>
              <NumberFormat value={saldo_credito_aprox}thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>
            </p>
          </div>
        </div>
        <div style={sectionText}>
          <p style={titleContentText}>
            <strong>Nuevas  oportunidades  de  crédito</strong>
          </p>
          <div style={optionCredit}>
            <p style={optionCreditTitle}>
              <strong>Opción 1</strong>
            </p>
            <p style={contentText}>
              <strong>Cuotas pendientes: </strong> {opcion_1}
            </p>
            <p style={contentText}>
              <strong> Valor cuota: </strong> <NumberFormat value={valorNuevaCuota(opcion_1)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>
            </p>
            <p style={contentText}>
              <strong> Abono adicional a cuotas: </strong> <NumberFormat value={(valorNuevaCuota(opcion_1) - valor_cuota).toFixed(0)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>

            </p>
            <p style={contentText}>
              <strong>Valor Total aproximado a pagar: </strong> <NumberFormat value={(opcion_1 * valorNuevaCuota(opcion_1)).toFixed(0)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>

            </p>
            <p style={contentText}>
              <strong>Intereses ahorrados </strong> <NumberFormat value={(interesesCliente - valorNuevosIntereses(opcion_1)).toFixed(0)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>
            </p>
            <p style={contentTextHonorarios}>
            <strong> Honorarios  5%  o  tarifa  mínima  (Pago  a  éxito)  -  IVA  Incluido : </strong>
            <NumberFormat value={(
                (interesesCliente - valorNuevosIntereses(opcion_1)) *
                0.05 *
                1.19
              ).toFixed(0)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>

            </p>
          </div>
          <div style={optionCredit}>
            <p style={optionCreditTitle}>
              <strong>Opción 2</strong>
            </p>
            <p style={contentText}>
              <strong>Cuotas pendientes: </strong> {opcion_2}
            </p>
            <p style={contentText}>
              <strong> Valor cuota: </strong> <NumberFormat value={valorNuevaCuota(opcion_2)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>
            </p>
            <p style={contentText}>
              <strong> Abono adicional a cuotas: </strong> <NumberFormat value={(valorNuevaCuota(opcion_2) - valor_cuota).toFixed(0)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>

            </p>
            <p style={contentText}>
              <strong>Valor Total aproximado a pagar: </strong> <NumberFormat value={(opcion_2 * valorNuevaCuota(opcion_2)).toFixed(0)}thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>

            </p>
            <p style={contentText}>
              <strong>Intereses ahorrados </strong>
              <NumberFormat value={(interesesCliente - valorNuevosIntereses(opcion_2)).toFixed(0)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>

            </p>
            <p style={contentTextHonorarios}>
              <strong> Honorarios  5%  o  tarifa  mínima  (Pago  a  éxito)  -  IVA  Incluido : </strong>
              <NumberFormat value={(
                (interesesCliente - valorNuevosIntereses(opcion_2)) *
                0.05 *
                1.19
              ).toFixed(0)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>

            </p>
          </div>
          <div style={optionCredit}>
            <p style={optionCreditTitle}>
              <strong>Opción 3</strong>
            </p>
            <p style={contentText}>
              <strong>Cuotas pendientes: </strong> {opcion_3}
            </p>
            <p style={contentText}>
              <strong> Valor cuota: </strong> <NumberFormat value={valorNuevaCuota(opcion_3)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>
            </p>
            <p style={contentText}>
              <strong> Abono adicional a cuotas: </strong> <NumberFormat value={(valorNuevaCuota(opcion_3) - valor_cuota).toFixed(0)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>
            </p>
            <p style={contentText}>
              <strong>Valor Total aproximado a pagar: </strong> <NumberFormat value={(opcion_3 * valorNuevaCuota(opcion_3)).toFixed(0)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>

            </p>
            <p style={contentText}>
              <strong>Intereses ahorrados </strong> <NumberFormat value={(interesesCliente - valorNuevosIntereses(opcion_3)).toFixed(0)} thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>

            </p>
            <p style={contentTextHonorarios}>
            <strong> Honorarios  5%  o  tarifa  mínima  (Pago  a  éxito)  -  IVA  Incluido : </strong>
            <NumberFormat value={(
                (interesesCliente - valorNuevosIntereses(opcion_3)) *
                0.05 *
                1.19
              ).toFixed(0)}thousandSeparator="." displayType="text" decimalSeparator="," prefix="$ "/>
            </p>
          </div>
        </div>
        <p style={sectionText}>
          Disfrute pagando su casa hasta 7 años antes. Ahorrando hasta {} de
          intereses. Estos beneficios son manteniendo el crédito en su banco
          <strong>{banco}</strong>. Proceso amparado dentro del marco dela ley
          546/99 (Ley de vivienda)
        </p>
        <p style={sectionText}>
          <span>Firma: ___________________________</span>
          <span>Apruebo la reducción No: ______________________</span>
        </p>
        <p>
          El pago de honorarios es contra resultado, únicamentenicamente en la
          cuenta bancaria de la compañia o con tarjeta de crédito. El presente
          estudio es propiedad intelectual y de uso exclusivo de <strong>Ahorro
          Inteligente S.A.S.</strong> Prohibido su uso, reproducción total o parcial por
          cualquier medio, sin autorización expresa de la compañia.
        </p>
        <p style={Footer}> Calle 32 No 13-32 Oficina 502, Bogotá, horrointeligente.co, consultorfinanciero@ahorrointeligente.co</p>
      </div>
    </article>
  );
};

export default PrintPdf;
