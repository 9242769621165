export const FieldForm: any[] = [
  {
    legend: 'Datos Básicos',
    id: 'DB',
    fields: [
      {
        label: 'Nombre',
        placeholder: 'Nombre',
        item: 'nombre',
        type: 'text',
      },
      {
        label: 'Apellido',
        placeholder: 'Apellido',
        item: 'apellido',
        type: 'text',
      },
    ]
  },
  {
    legend: 'Datos del crédito',
    msj: 'Por favor diligencie los datos de acuerdo a su extracto bancario',
    id: 'DC',
    fields: [
      {
        label: 'Nombre de la entidad financiera',
        placeholder: 'Nombre de la entidad financiera',
        item: 'banco',
        type: 'text',
      },
      {
        label: 'Número del crédito',
        placeholder: 'Número del crédito',
        item: 'numero_credito',
        type: 'number',
      },
      {
        label: 'Saldo Crédito',
        placeholder: 'Saldo Crédito',
        item: 'saldo_credito',
        type: 'number',
      },
      {
        label: 'Cuotas pendientes',
        placeholder: 'Cuotas pendientes',
        item: 'cuotas_pendientes',
        type: 'number',
      },
      {
        label: 'Valor cuota',
        placeholder: 'Valor cuota',
        item: 'valor_cuota',
        type: 'number',
      },
      {
        label: 'Tasa de intereses M.V.',
        placeholder: 'Tasa de intereses M.V.',
        item: 'intereses',
        type: 'number',
      },
    ]
  },
  {
    legend: 'Datos Adicionales',
    id: 'DA',
    fields: [
      {
        label: 'Opción 1',
        placeholder: 'Opción 1',
        item: 'opcion_1',
        type: 'number',
      },
      {
        label: 'Opción 2',
        placeholder: 'Opción 2',
        item: 'opcion_2',
        type: 'number',
      },
      {
      label: 'Opción 3',
      placeholder: 'Opción 3',
      item: 'opcion_3',
      type: 'number',
    },
      {
        label: 'Asesor(a)',
        placeholder: 'Asesor(a)',
        item: 'asesor',
        type: 'text',
      }
    ]
  },
];

