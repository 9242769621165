import { useState } from "react";
import Layout from "../components/Layout";
import { Formik, Form, Field } from "formik";
import { Simulador } from "../shared/interface/simulador";
import { FieldForm } from "../shared/data/form";
import PrintPdf from "../components/PrintPdf";
import { jsPDF } from "jspdf";

const PDF_CONTAINER = 'cotizacion';

const Home = () => {
  const [dataForm, setDataForm] = useState<Simulador>({
    nombre: "",
    apellido: "",
    banco: "",
    numero_credito: 0,
    cuotas_pendientes: 0,
    valor_cuota: 0,
    saldo_credito: 0,
    intereses: 0,
    asesor: "",
    opcion_1: 0,
    opcion_2: 0,
    opcion_3: 0,
  });

  const [viewPdf, setviewPdf] = useState(false);

  const initialValues: Simulador = {
    nombre: "",
    apellido: "",
    banco: "",
    numero_credito: 0,
    cuotas_pendientes: 0,
    valor_cuota: 0,
    saldo_credito: 0,
    intereses: 0,
    asesor: "",
    opcion_1: 0,
    opcion_2: 0,
    opcion_3: 0,
  };

  const contentText: any = {
    margin: "0",
  };

  const PrintPDF = () => {
    const pdf = new jsPDF("p", "pt", "letter", true);
    pdf.html(document.getElementById(PDF_CONTAINER) as HTMLElement).then(() => {
      pdf.save(`cotizacion_ahorro_inteligente.pdf`);
    });
  };

  return (
    <Layout>
      <section className="finance-data">
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            console.log({ values, actions });
            setDataForm(values);
            console.log(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
            setviewPdf(true);
          }}
        >
          <Form>
            {FieldForm.map((fieldset: any, index: number) => (
              <fieldset key={index}>
                <legend style={fieldset.msj ? contentText : null  } >{fieldset.legend}</legend>
                {fieldset.msj ? <small className="msj-legend">*"{fieldset.msj}"</small> : null}
                {fieldset.fields.map((field: any) => (
                  <div key={field.item} className="form-group">
                    <label htmlFor="nombre">{field.label}</label>
                    <Field
                      id={field.item}
                      className="form-control"
                      type={field.type}
                      name={field.item}
                      placeholder={field.placeholder}
                    />
                  </div>
                ))}
              </fieldset>
            ))}
            <button type="submit" className="btn btn-primary">Simular Opciones</button>
          </Form>
        </Formik>
      </section>
      { viewPdf ? <section className="pdf-preview">
        <PrintPdf {...dataForm} />
        <div className="btn-action">
          <button className="btn btn-primary" onClick={PrintPDF}>Imprimir</button>
          <button className="btn btn-secondary" onClick={() => window.location.reload()}>Crear nueva cotización</button>
        </div>
      </section> : <section className="pdf-preview"> Sin cotización generada </section> }
    </Layout>
  );
};

export default Home;
